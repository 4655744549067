exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-all-peptides-index-tsx": () => import("./../../../src/pages/all-peptides/index.tsx" /* webpackChunkName: "component---src-pages-all-peptides-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-category-index-tsx": () => import("./../../../src/pages/category/index.tsx" /* webpackChunkName: "component---src-pages-category-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-my-account-index-tsx": () => import("./../../../src/pages/my-account/index.tsx" /* webpackChunkName: "component---src-pages-my-account-index-tsx" */),
  "component---src-pages-my-address-index-tsx": () => import("./../../../src/pages/my-address/index.tsx" /* webpackChunkName: "component---src-pages-my-address-index-tsx" */),
  "component---src-pages-my-order-index-tsx": () => import("./../../../src/pages/my-order/index.tsx" /* webpackChunkName: "component---src-pages-my-order-index-tsx" */),
  "component---src-pages-place-order-tsx": () => import("./../../../src/pages/place-order.tsx" /* webpackChunkName: "component---src-pages-place-order-tsx" */),
  "component---src-pages-products-[name]-tsx": () => import("./../../../src/pages/products/[name].tsx" /* webpackChunkName: "component---src-pages-products-[name]-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/CmsPage.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-product-list-tsx": () => import("./../../../src/templates/productList.tsx" /* webpackChunkName: "component---src-templates-product-list-tsx" */)
}

