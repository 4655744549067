import React from 'react';
import { Provider } from 'react-redux';
import store from './src/store';

import "./src/styles/global.css"
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);

// export const onRenderBody = ({ setHeadComponents }) => {
//   setHeadComponents([
//     <script
//       key="yotpo-script"
//       type="text/javascript"
//       async
//       defer
//       src='https://staticw2.yotpo.com/32WCW8rg0gbhiSy1IHEZjRuKH0UynB7Va83EdmlP/widget.js'
//     />,
//   ]);
// };


// export const onRenderBody = ({ setHeadComponents }) => {
//   setHeadComponents([
//     <link
//     key="favicon"
//     rel="icon"
//     type="image/png" 
//     href="./favicon.png" 
//     />,
//   ]);
// };


