import axios from "axios";
import wooCommerce from "../../utils/woocommerce";
import { useStaticQuery, graphql } from "gatsby";

interface IdArray {
  product_id: number;
  quantity: number;
}

export const getProducts = async (
  page: number = 1,
  order_by: string = "date",
  sortType: string = "asc",
  categoryId: string
) => {
  // Ensure environment variable is defined and has a default value
  const perPage = process.env.GRAPHQL_RECORD_PER_PAGE || 10; // Default to 10 if not set

  // Construct parameters based on order_by
  const [orderby, order] =
    order_by === "price-desc" ? order_by.split("-") : [order_by, sortType];

  const params = {
    page,
    per_page: perPage,
    orderby,
    order,
    category: categoryId,
    _fields:
      "id,name, slug, regular_price, stock_quantity, yoast_head, price, images, stock_status, variations",
  };

  try {
    // Fetch products from WooCommerce API
    const response = await wooCommerce.get("products", { params });

    // Return data and pagination information
    return {
      data: response.data,
      total: response.headers["x-wp-total"] || 0,
      page: response.headers["x-wp-totalpages"] || 0,
    };
  } catch (error) {
    // Handle errors and log them
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }

    // Return empty result on error
    return {
      data: [],
      total: 0,
      page: 0,
    };
  }
};

export const getProductDetails = async (product_id: string, slug: string) => {
  try {
    let pid: string;
    if (product_id) {
      pid = product_id;
    } else {
      const pDetails = await wooCommerce.get(`products/?slug=${slug}`);
      pid = pDetails.data[0].id;
    }

    // Initiate parallel API requests
    const [response, varient] = await Promise.all([
      wooCommerce.get(`products/${pid}`),
      wooCommerce.get(`/products/${pid}/variations?order=asc`),
    ]);

    const ids = response.data.related_ids.toString();

    // Additional parallel API requests for related and grouped products
    const [related_product, grouped_product] = await Promise.all([
      wooCommerce.get(`products/?include=${ids}`),
      response.data.grouped_products?.length > 0
        ? wooCommerce.get(`products/?include=${response.data.grouped_products}`)
        : Promise.resolve({ data: [] }),
    ]);
    const sortedVariants = varient.data.sort(
      (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
    );
    // console.log(sortedVariants,"sortedVariants")
    return {
      data: response.data,
      varient: sortedVariants,
      related_product: related_product.data,
      grouped_product: grouped_product.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const getProductVarients = async (productId: any) => {
  try {
    const varients: any = await wooCommerce.get(
      `/products/${productId}/variations?order=asc`
    );
    return varients.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
  }
};

export const getFeaturedProduct = async () => {
  const data = useStaticQuery(graphql`
    query {
      allWcProducts(filter: { featured: { eq: true } }) {
        nodes {
          id
          wordpress_id
          slug
          name
          price
          sale_price
          permalink
          on_sale
          short_description
          images {
            src
            alt
            id
          }
        }
      }
    }
  `);
  return data.allWcProducts.nodes;
};

export const getVarients = async (id: any) => {
  try {
    const varient = await wooCommerce.get(`/products/${id}/variations`);
    return varient.data;
  } catch (error: any) {
    // console.log(error);
    // throw new Error(error.message);
  }
};

export const CheckStock = async (
  product_id: string | number,
  quantity: number | any
) => {
  try {
    const product = await wooCommerce.get(`/products/${product_id}`);
    const currentStock = product.data.stock_quantity;
    const manageStock = product.data.manage_stock;
    // IF MANAGE STOCK IS GREATER TRUE THEN CHECK STOCK
    if (manageStock) {
      if (currentStock >= quantity) {
        return {
          instock: true,
          stock: currentStock,
          productData: product.data,
          manageStock: manageStock,
        };
      } else {
        return {
          instock: false,
          stock: currentStock,
          productData: product.data,
          manageStock: manageStock,
        };
      }
    } else {
      return {
        instock: true,
        stock: currentStock,
        productData: product.data,
        manageStock: manageStock,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const searchProduct = async (query: any) => {
  try {
    let result;
    const { data }: any = await axios.get(
      `https://staging-backend.spartanpeptides.com/wp-json/search-api/v2/product/?title=${query.trim()}`
    );
    let ids: any = [];

    if (data.length > 0) {
      data.map((product: any) => {
        ids.push(product.ID);
      });
      const results = await wooCommerce.get(`/products/?include=${ids}`);
      result = results.data;
    } else {
      result = [];
    }
    return {
      ids: ids,
      data: result,
    };
  } catch (error: any) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const getGroupProduct = async (product: any) => {
  try {
    let grouped_product: any = [];
    const linkedIds = product;
    grouped_product = await wooCommerce.get(`products/?include=${linkedIds}`);
    return {
      grouped_product: grouped_product.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const getVariableProduct = async (productId: any) => {
  try {
    let pid = productId;
    let varient_product: any = [];
    varient_product = await wooCommerce.get(`/products/${pid}/variations`);
    return {
      varient_product: varient_product.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const getCategoryAdditionalInformation = (categorySlug: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${process.env.GATSBY_WORDPRESS_BASE_URL}api/v1/fetch-additional-information?slug=${categorySlug}`
      );

      return resolve(response.data);
    } catch (error) {
      // console.error(error, "<<-- Error in getting category information");
    }
  });
};

// RELATED PRODUCT
export const getRelatedProduct = async (product_id: String, slug: string) => {
  try {
    let pid;
    if (product_id) {
      pid = product_id;
    } else {
      const pDetails = await wooCommerce.get(`products/?slug=${slug}`);
      pid = pDetails.data[0].id;
    }

    const response = await wooCommerce.get(`products/${pid}`);
    const ids = response.data.related_ids.toString();
    const related_product = await wooCommerce.get(`products/?include=${ids}`);

    return {
      data: response.data,
      related_product: related_product.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const getGroupProductTotalPrice = async (product: any) => {
  try {
    let grouped_product: any = [];
    const linkedIds = product;
    grouped_product = await wooCommerce.get(`products/?include=${linkedIds}`);
    // GETING TOTAL PRICE 
    const totalPrice = grouped_product.data.reduce((accumulator:number, item:any) => {
      return accumulator + Number(item.price);
    }, 0);
  
    return {
      total: totalPrice.toFixed(2),
    };
  } catch (error:any) {
    console.log("ERROR WHILE CALCULATING GROUPED PRODUCT TOTAL: ", error.message)
  }
};

// https://staging-backend.spartanpeptides.com/wp-json/search-api/v2/product/?title=semax
